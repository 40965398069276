/* eslint-disable comma-dangle */
import { IncomingMessage } from 'http';
import { MetadataValue } from '@grpc/grpc-js';
import Cookies from 'universal-cookie';
import type { RedisOptions } from 'ioredis';
import { Image } from '@vtblife/classified-model/dist-grpcjs/classified/common_pb';
import { QuarterEnum } from '@vtblife/classified-model/dist-grpcjs/util/common_pb';
import { HeaderConfig, MenuConfig } from '@vtblife/layout-config/dist/types';
import type { Purpose } from '@vtblife/mortgage-api/build-grpcjs/ru/vtblife/mortgage/v1/model/common_pb';
import type { MortgageProperty } from '@vtblife/mortgage-api/build-grpcjs/ru/vtblife/mortgage/v1/model/mortgage_property_pb';
import type { HivePluginOptions } from '@graphql-hive/client';
import { NewBuildingWhitelabelEnum } from '../server/enums/NewBuildingWhitelabelEnum';
import type { UserInfo } from '../server/lib/auth';
import { buildGqlContext } from '../server/gql-context';
import type { MortgageClientTimeouts } from '../api/MortgageClient';
import type { BankSuggestClientTimeouts } from '../api/BankSuggestClient';
import type {
    Mg2CalculationTimeouts,
    Mg2PreviewCalculationTimeouts,
    Mg2BorrowerTimeouts,
    Mg2RosterClientTimeouts,
} from '../server/mortgage2/clients';
import type { MortgageCalculatorClientTimeouts } from '../api/MortgageCalculatorClient';
import type { PreferencesPersonalClientTimeouts } from '../api/PreferencesPersonalClient';
import type { PreferencesPersonalOpenClientTimeouts } from '../api/PreferencesPersonalOpenClient';
import type { PreferencesBusinessClientTimeouts } from '../api/PreferencesBusinessClient';
import type { OfferPlacementClientTimeouts } from '../server/offer-placement/OfferPlacementClient';
import type { ClickerTrackerClientTimeouts } from '../server/clicker-tracker/ClickerTrackerClient';
import type { ClickerStatsClientTimeouts } from '../server/clicker-stats/ClickerStatsClient';
import type { FeedsClientTimeouts } from '../src/domains/user-offer-stat/FeedsClient';
import type { BastilleExternalClientTimeouts } from '../api/BastilleExternalClient';
import type { MonetizationClientTimeouts } from '../api/MonetizationClient';
import type { BillingApiClientTimeouts } from '../api/BillingApiClient';
import type { VarlamovClientTimeouts } from '../api/VarlamovClient';
import type { SearchClientTimeouts } from '../api/SearchClient';
import type { ExternalGeoClientTimeouts } from '../api/ExternalGeoClient';
import type { GeoSuggestClientTimeouts } from '../api/GeoSuggestClient';
import type { GeoSupportedRegionsClientTimeouts } from '../api/GeoSupportedRegionsClient';
import type { DeveloperSearchClientTimeouts } from '../api/DeveloperSearchClient';
import type { NewBuildingRootRegionsClientTimeouts } from '../api/NewBuildingRootRegionsClient';
import type { NewBuildingSearchClientTimeouts } from '../api/NewBuildingSearchClient';
import type { NewBuildingPreferencesClientTimeouts } from '../api/NewBuildingPreferencesClient';
import type { SuggestClientTimeouts } from '../api/SuggestClient';
import type { UserDetailsClientTimeouts } from '../api/UserDetailsClient';
import type { MailConfirmationClientTimeouts } from '../api/MailConfirmationClients';
import type { UserProfileClientTimeouts } from '../api/UserProfileClient';
import type { UserProfileV2ClientTimeouts } from '../api/UserProfileV2Client';
import type { PersonsSearchClientTimeouts } from '../api/PersonsSearchClient';
import type { CompanyApiClientTimeouts } from '../api/CompanyApiClient';
import type { EmailGatewayClientTimeouts } from '../api/EmailGatewayClient';
import type { RegistraturaClientTimeouts } from '../api/RegistraturaClient';
import type { OnboardingApiClientTimeouts } from '../api/OnboardingClient';
import type { SimilarsClientTimeouts } from '../api/SimilarsClient';
import type { SportlotoClientTimeouts } from '../api/SportlotoClient';
import type { CallbackClientTimeouts } from '../api/CallbackClient';
import type { ShawshankClientTimeouts } from '../api/ShawshankClient';
import type { ParoleClientTimeouts } from '../api/ParoleClient';
import type { OfficersClientTimeouts } from '../api/OfficersClient';
import type { PagesClientTimeouts } from '../api/PagesClient';
import type { RepairClientTimeouts } from '../api/RepairClient';
import type { DocumentStorageClientTimeouts } from '../api/DocumentStorageClient';
import type { MangoustClientTimeouts } from '../api/MangoustClient';
import type { SRGClientData } from '../api/SRGClient';
import { RouletteClientTimeouts } from '../api/RouletteClient';
import { Mg2DynamoTimeouts } from '../server/mortgage2/dynamo/client';
import { MortgageDictionaryClientTimeouts } from '../api/MortgageDictionaryClient';
import { Mg2IdxAdapterTimeouts } from '../server/mortgage2/idx/client';
import { Mg2DispatchTimeouts } from '../server/mortgage2/dispatch/client';
import { Mg2DispatchFileTimeouts } from '../server/mortgage2/dispatch/clientFiles';
import { Mg2DealerTimeouts } from '../server/mortgage2/dealer/client';
import { Mg2DocumentsLoaderTimeouts } from '../server/mortgage2/documentsLoader/client';

export * from '../server/typenames';

export enum View {
    mobile = 'mobile',
    desktop = 'desktop'
}

export type MetadataOptions = Record<string, MetadataValue>;

export interface Request extends IncomingMessage {
    url: string;
    xTraceId: string;
    body: object;
    searchIndex: NewBuildingWhitelabelEnum;
    universalCookies: Cookies;
    user?: UserInfo;
}

export type ConfigItem<T extends Record<string, number>> = {
    host: string;
    timeouts: T;
}

export interface Config {
    env: string;
    isBranch?: boolean;
    M2_HOST: string;

    API_MORTGAGE: ConfigItem<MortgageClientTimeouts>;
    API_MORTGAGE_SEARCH: ConfigItem<BankSuggestClientTimeouts>;
    API_MORTGAGE_DICTIONARY: ConfigItem<MortgageDictionaryClientTimeouts>;
    API_MORTGAGE_HAGGLER: ConfigItem<Mg2CalculationTimeouts & Mg2PreviewCalculationTimeouts>;
    API_MORTGAGE_ROSTER: ConfigItem<Mg2RosterClientTimeouts>;
    API_MORTGAGE_BORROWER: ConfigItem<Mg2BorrowerTimeouts>;
    API_DYNAMO: ConfigItem<Mg2DynamoTimeouts>;
    API_RELAY: ConfigItem<Mg2DispatchTimeouts>;
    API_RELAY_FILES: ConfigItem<Mg2DispatchFileTimeouts>;
    API_DEALER: ConfigItem<Mg2DealerTimeouts>;
    IDX_ADAPTER: ConfigItem<Mg2IdxAdapterTimeouts>;
    API_MORTGAGE_CALCULATOR: ConfigItem<MortgageCalculatorClientTimeouts>;
    API_PREFERENCES_PERSONAL: ConfigItem<PreferencesPersonalClientTimeouts>;
    API_PREFERENCES_PERSONAL_OPEN: ConfigItem<PreferencesPersonalOpenClientTimeouts>;
    API_PREFERENCES_BUSINESS: ConfigItem<PreferencesBusinessClientTimeouts>;
    API_OFFER_PLACEMENT: ConfigItem<OfferPlacementClientTimeouts>;
    API_CLICKER_TRACKER: ConfigItem<ClickerTrackerClientTimeouts>;
    API_CLICKER_STATS: ConfigItem<ClickerStatsClientTimeouts>;
    API_FEEDS: ConfigItem<FeedsClientTimeouts>;
    API_BASTILLE_EXTERNAL: ConfigItem<BastilleExternalClientTimeouts>;
    API_MONETIZATION: ConfigItem<MonetizationClientTimeouts>;
    API_BILLING: ConfigItem<BillingApiClientTimeouts>;
    API_VARLAMOV: ConfigItem<VarlamovClientTimeouts>;
    GRPS_API: string;
    staticPath?: string;
    API_OFFER_SEARCH: ConfigItem<SearchClientTimeouts>;
    API_GEO_SEARCH: ConfigItem<ExternalGeoClientTimeouts | GeoSuggestClientTimeouts>;
    API_GEO_ROOT_REGIONS: ConfigItem<GeoSupportedRegionsClientTimeouts>;
    API_NEW_BUILDING_SEARCH: ConfigItem<
        DeveloperSearchClientTimeouts |
        NewBuildingRootRegionsClientTimeouts |
        NewBuildingSearchClientTimeouts
    >;
    API_NEW_BUILDING_WHITELABEL_SEARCH: ConfigItem<
        DeveloperSearchClientTimeouts |
        NewBuildingRootRegionsClientTimeouts |
        NewBuildingSearchClientTimeouts
    >;

    API_NEW_BUILDING_PREFERENCES: ConfigItem<NewBuildingPreferencesClientTimeouts>;
    API_SUGGEST: ConfigItem<SuggestClientTimeouts>;
    API_AUTH: ConfigItem<UserDetailsClientTimeouts>;
    API_PROFILE: ConfigItem<
        MailConfirmationClientTimeouts |
        UserProfileClientTimeouts |
        UserProfileV2ClientTimeouts
    >;
    API_PERSON: ConfigItem<PersonsSearchClientTimeouts>;
    API_COMPANY: ConfigItem<CompanyApiClientTimeouts>;
    API_REPAIR: string;
    API_EMAIL_GATEWAY: ConfigItem<EmailGatewayClientTimeouts>;
    NEW_BUILDING_CALLBACK_DEFAULT_EMAIL_SENDER: string;
    NEW_BUILDING_CALLBACK_MSK_EMAIL_SENDER: string;
    NEW_BUILDING_CALLBACK_SPB_EMAIL_SENDER: string;
    NEW_BUILDING_ONLINE_BOOKING_MSK_EMAIL_SENDER: string;
    NEW_BUILDING_ONLINE_BOOKING_SPB_EMAIL_SENDER: string;
    NEW_BUILDING_QUIZ_URL: string;
    NEW_BUILDING_DESCRIPTION_URL: string;
    NEW_BUILDING_SAMOLET_DEVELOPER_PROMO?: string;
    NEW_BUILDING_SAMOLET_PLUS_DEVELOPER_PROMO?: string;
    NEW_BUILDING_CDS_DEVELOPER_PROMO?: string;
    SALES_CALLBACK_EMAIL_RECIPIENT: string;
    CLASSIFIED_SEARCH_CALLBACK_EMAIL_SENDER: string;
    CLASSIFIED_SEARCH_PATH_SEO_FILES: string;
    MORTGAGE_CALLBACK_EMAIL_RECIPIENT: string;
    MORTGAGE_CALC_ANALYTICS_EMAIL_RECIPIENT: string;
    FEED_STATS_EMAIL_RECIPIENT: string;
    FEED_STATS_EMAIL_SENDER: string;
    API_REGISTRATURA: ConfigItem<RegistraturaClientTimeouts>;
    S3_HOST: string;
    API_ONBOARDING: ConfigItem<OnboardingApiClientTimeouts>;
    port?: number;
    MAP_TILE_URL: string;
    API_SLACK_HOOKS: string;
    API_MM_HOOKS: string;
    API_OFFERS_SIMILAR: ConfigItem<SimilarsClientTimeouts>;
    GRAPHQL_GATEWAY: string;
    API_MENU: string;
    API_SPORTLOTO: ConfigItem<SportlotoClientTimeouts>;
    API_NEWS: string;
    API_CALLBACK: ConfigItem<CallbackClientTimeouts>;
    tracingReporter?: {
        agentHost?: string;
        agentPort?: number;
        collectorEndpoint?: string;
    };
    redis?: RedisOptions;
    REMONT: {
        LANDING_IZHS_EMAIL_RECIPIENT: string[];
        LANDING_IZHS_EMAIL_FROM: string;
    };
    MORTGAGE: {
        LANDING_IT_EMAIL_RECIPIENT: string[];
        LANDING_IT_EMAIL_FROM: string;
    };
    API_CAPTCHA_VALIDATOR: string;

    API_HOOSEGOW_SHAWSHANK: ConfigItem<ShawshankClientTimeouts>;
    API_HOOSEGOW_PAROLE: ConfigItem<ParoleClientTimeouts>;
    API_BANK_SERVICE: string;
    API_HOOSEGOW_OFFICER: ConfigItem<OfficersClientTimeouts>;
    API_PAGES: ConfigItem<PagesClientTimeouts>;
    RIELTORS_MSK_CALLBACK_EMAIL_RECIPIENTS: string[];
    RIELTORS_SPB_CALLBACK_EMAIL_RECIPIENTS: string[];
    API_REPAIR_CL: ConfigItem<RepairClientTimeouts>;
    API_DOCUMENT_STORAGE: ConfigItem<DocumentStorageClientTimeouts>;
    API_MANGOUST: ConfigItem<MangoustClientTimeouts>;
    hive: Partial<HivePluginOptions>;
    API_SRG: SRGClientData;
    API_ROULETTE: ConfigItem<RouletteClientTimeouts>;
    API_DOCUMENT_GENERATOR: ConfigItem<Mg2DocumentsLoaderTimeouts>;
}

export type GraphqlContext = ReturnType<typeof buildGqlContext>;

export interface Breadcrumbs {
    items: BreadcrumbsItem[];
    rdfMeta: string;
}

export interface BreadcrumbsItem {
    title: string;
    path: string;
    active?: boolean;
}

export interface Menu extends MenuConfig{
    isAuth: boolean;
}
export interface Header extends HeaderConfig{
    isAuth: boolean;
}
export interface HeaderPersonalButton {
    url: string;
    professionalUrl?: string;
    authorizedUrl?: string;
}

export interface RepairRequestResult {
    errorText: string;
}

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export type NewBuildingCardSeoText = {
    appInstance: string;
    site: string;
    siteFull: string;
    titleNominative: string;
    titleLocative: string;
    fullTypeNominative: string;
    typeGenitive: string;
    titleGenitive: string;
    address: string;
    priceMin: number | null;
    developerName: string | null;
    hasApartmentsOnly: boolean;
    seoTitle?: string;
    geo?: string;
    geoLocative?: string;
    metaTitle?: string;
    metaDescription?: string;
    name: string;
}

export type NewBuildingOffersSeoText = {
    dealType: {
        verb: string;
        noun: string;
    };
    realtyObject: {
        singular: {
            accusative: string;
        };
        plural: {
            nominative: string;
            genitive: string;
            accusative: string;
        };
    };
    typeLocative: string;
    mainName: string;
    additionalName: string;
    regionLocative?: string;
}

interface IElementSeoText {
    header: string;
    title: string;
    metaDescription: string;
    seoInfo: string;
}

export interface SearchNewBuildingsSeoText {
    region: string;
    district: IElementSeoText | null;
    metro: IElementSeoText | null;
    buildingClass: IElementSeoText | null;
    commissioning: IElementSeoText | null;
    room: IElementSeoText | null;
    finishingType: IElementSeoText | null;
    appInstance: string;
}

export interface IConstructionProgress {
    image: Image.AsObject;
    year: number;
    quarter: QuarterEnum.Quarter;
    address?: string;
    baseUrl?: string;
}

export enum InspectionStatusEnum {
    Passed = 'PASSED',
    Failed = 'FAILED'
}

export interface IInspectionStatus {
    isVerified?: boolean;
    value: InspectionStatusEnum;
    detailText: string;
    desktopLink?: string;
    mobileLink?: string;
    statusText?: string;
}

export interface INewBuildingRouteParams {
    id: number;
    regionId: number;
    region: string;
    subRegion: string | null;
    subRegionDisplayName: string | null;
    type: string;
    name: string;
}

export type ValueOf<T> = T[keyof T];

export interface INewBuildingMortgageParams {
    term: number;
    regionId: number;
    isMainRegion: boolean;
    propertyCost: number;
    ownAmount: number;
    purpose: ValueOf<typeof Purpose.PurposeValue>;
    claimType: ValueOf<typeof MortgageProperty.ClaimType>;
}

export type NewBuildingOfferMortgageParamsType = Pick<
    INewBuildingMortgageParams, 'term' | 'propertyCost' | 'ownAmount' | 'purpose' | 'claimType'
>
